<template>
  <div class="grey darken-4">
    <BannerHeader
      :height="$vuetify.breakpoint.mdAndDown ? '25vh' : '50vh'"
      title="Services"
      subtitle="Building Foundations and Relationships that stand the test of time"
    />
    <v-container fluid class="px-6">
      <v-row class="my-4">
        <template v-for="i in services">
          <v-col
            :key="i.route"
            class="cursor"
            @click="$router.push({ path: `${i.route}`})"
            cols="12"
            sm="6"
            lg="4"
          >
            <div
              class="black rounded elevation-12"
              data-blink-ops="quality: smart; blur: 5; scale-crop: 700x525; sharpen: 20; setfill: ece3d2"
              :data-blink-uuid="i.uuid"
              style="height: 325px;"
            >
              <div class="overlay fill-height d-flex align-center justify-center rounded">
                <div class="text-uppercase text-h5 text-md-h4 white--text">
                  {{ i.title }}
                  <v-divider class="primary"/>
                </div>
              </div>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BannerHeader from '@/core/components/BannerHeader'
export default {
  metaInfo: {
    title: 'Services',
    description: 'C&C Industrial is a turnkey Concrete and Civil contractor providing Preconstruction, Civil, Pumping & Conveying, Place & Finish, Concrete Washout and more'
  },
  components: {
    BannerHeader
  },
  data () {
    return {
      services: [
        {
          title: 'Concrete Services',
          subtitle: '',
          route: '/concrete-services',
          uuid: '235d22c3-8194-4f27-bd97-ee32f09b2e7c'
        },
        {
          title: 'Preconstruction',
          subtitle: '',
          route: '/preconstruction',
          uuid: 'e0f67cbd-c37c-41da-be16-c4a6a065c054'
        },
        {
          title: 'Civil Division',
          subtitle: '',
          route: '/civil-division',
          uuid: 'ee142958-7476-43ce-a21b-e9635fc70eda'
        },
        {
          title: 'Pumping & Conveying',
          subtitle: '',
          route: '/concrete-pumping-conveying',
          uuid: 'a3c2f4cd-6467-4604-bf97-c38b534e052e'
        },
        {
          title: 'Place & Finish',
          subtitle: '',
          route: '/place-and-finish',
          uuid: '439c62b1-d201-4fb2-a578-f522c13d17c4'
        },
        {
          title: 'Washout Services',
          subtitle: '',
          route: '/washout-services',
          uuid: 'e22d937d-0449-41fe-bc5c-346111e982ef'
        }
      ]
    }
  }
}
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.45);
  transition: background-color 100ms linear;
}
.overlay:hover {
  background-color: rgba(0, 0, 0, 0.80);
  transition: background-color 180ms linear;
}
</style>
