<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        lg="6"
        xl="6"
        class="grey--text text--lighten-2"
      >
        <p>
          <span class="cc-paragraph-lead primary--text">Our preconstruction</span> team utilizes the latest technology in project estimations. By using Mc2 & Onscreen Take Off we ensure accuracy in all projects. Our project managers use Microsoft Project Scheduling to help you in meeting the all-important job deadlines. Prior to mobilizing construction equipment & labor, projects begin in-house with AutoCAD layout followed by the actual job site layout utilizing fully robotic Total Station technology to ensure maximum accuracy, efficiency and cost savings. Workplace safety & health is incorporated into the planning process from start to finish.
        </p>
        <div class="text-right">
          <v-btn
            v-if="$vuetify.breakpoint.mdAndDown"
            color="info"
            elevation="0"
            small
            to="/preconstruction"
          >
            Learn More
            <v-icon right>
              fa-chevron-right
            </v-icon>
          </v-btn>
        </div>
      </v-col>
       <AboutLearnMore
        v-if="$vuetify.breakpoint.lgAndUp"
        :cta="cta"
        label="Preconstruction"
        uuid="e0f67cbd-c37c-41da-be16-c4a6a065c054"
        url="/preconstruction"
      />
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    cta: {
      type: String,
      default: 'right'
    }
  },
  components: {
    AboutLearnMore: () => import(/* webpackChunkName: "about-learn-more" */ './AboutLearnMore.vue')
  }
}
</script>
