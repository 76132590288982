<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        lg="6"
        xl="6"
        class="grey--text text--lighten-2"
      >
        <p>
          <span class="cc-paragraph-lead primary--text">Our Civil Division</span> often goes hand in hand with concrete services. Our Civil Division is <span class="font-weight-bold white--text">Unlimited and Unclassified</span>. This allows C&C to perform any size civil project regardless of the complexity of the project. This division consists of company owned equipment from Bulldozers, Motor-graders, Large Excavators, and a fleet of Dump Trucks to include Trailer Dumps.
        </p>
        <p>The civil division is often the first of our staff on site. The civil division establishes the site for vertical construction. To begin a new site project, the process usually starts with clearing standing timber and grubbing the soil from stumps and organic material. Then by process of removing unsuitable soils and importing good material to provide a suitable area for construction. This division also incorporates the utility infrastructure into the project with the installation of underground Domestic Water, Sanitary Sewer, and Storm Water Drainage that are usually connected to City infrastructures.
        </p>
        <p>Upon completion of the projects our Civil Division will install site grading for Parking Lots to include Pavement, Striping, Signage, Curb-Gutter and Sidewalks.
        </p>
        <div class="text-right">
          <v-btn
            v-if="$vuetify.breakpoint.mdAndDown"
            class="mt-4"
            color="info"
            elevation="0"
            small
            to="/concrete-services#civil"
          >
            Learn More
            <v-icon right>
              fa-chevron-right
            </v-icon>
          </v-btn>
        </div>
      </v-col>
      <AboutLearnMore
        v-if="$vuetify.breakpoint.lgAndUp"
        :cta="cta"
        label="Civil Division"
        uuid="666b8e2f-ef0c-4009-879c-7693f0521dff"
        url="/civil-division"
      />
    </v-row>

  </div>
</template>

<script>
export default {
  props: {
    cta: {
      type: String,
      default: 'right'
    }
  },
  components: {
    AboutLearnMore: () => import(/* webpackChunkName: "about-learn-more" */ './AboutLearnMore.vue')
  }
}
</script>
