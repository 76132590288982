<template>
<section
      :data-blink-ops="$vuetify.breakpoint.smAndDown
        ? 'resize: 900; quality: smart; filter: sedis'
        : 'resize: 1920; quality: smart; filter: sedis'
        "
      data-blink-uuid="f3e06175-6eb8-4fd6-9fd1-3fcca731db01"
    >
      <div
        class="cc-overlay-2 d-flex flex-column align-center justify-center"
        style="min-height:85vh"
      >
  <v-container fluid>
    <v-row justify="center">
      <v-col
        cols="12"
        lg="6"
        class="grey--text text--lighten-3"
      >
        <h1 class="text-h4 mb-2 font-weight-bold text-uppercase">
          C&C Industrial, Inc
        </h1>
        <h2 class="text-h6 text-md-h5 grey--text font-italic">
          From the beginning
        </h2>
        <v-divider class="primary my-4" />
        <p>
          While Chris Collie was in high school, his dad bought him his first tractor and box blade to start his own small business. C&C Landscaping and Lawn Design. As he was very excited with this new equipment, he spent the next few years after graduation spreading dirt, grading yards, laying sod, and planting shrubbery.
        </p>
        <div :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''">
          <img
            data-blink-ops="resize: 350"
            data-blink-uuid="75405181-e6f2-4180-a01e-cd3e9fb01ff6"
            :class="`rounded elevation-8 ${$vuetify.breakpoint.smAndDown ? '' : 'float-right ml-2 mb-2'}`"
          />
        </div>
        <p>
          A close friend’s father was developing a mobile home estate with about (80) 20’ X 20’ concrete slabs that needed to be graded, formed, and poured. With very little experience, he found the right help and was able to coordinate the installation of 2 of these pads per day. This accomplishment alone, sparked his interest in concrete.
        </p>
        <p>
          Through contacts in the business, he was introduced to a Track Home Builder in Raleigh that was developing Patio Homes in various subdivisions. They spent the next 5 years doing that and then grew interested in the commercial concrete side of the business. He and several employees obtained certification in Post Tension and Pervious Concrete. Fortunately, that led to larger commercial and industrial projects.
        </p>
        <p>
          Around 2013 after constantly having the need of renting concrete pumps for numerous jobs, he decided to purchase one. This eventually led to a fleet of pumps and made us as a company realize the environmental need for concrete dumpsters and Washout services.
        </p>
        <p>
          Through the years we realized the need to incorporate a civil division within the company as too often this will go hand in hand with our concrete division. We are proud to have an Unlimited, Unclassified General Contractor license as well.
        </p>
        <p>
          What Chris is most proud of here at C&C Industrial, Inc. is our employees thru pride and ownership have brought us from that 1 tractor to over 150 pieces of equipment today. Our company willfully employees nearly 200 employees with an average tenure of over 7 years. With about 15% of whom have been with the company in excess of 10 years or more. We also utilize the resources of specialty trades (subcontractors) such as, Rod Busters to install reinforcements, Demo experts in renovations, and Utility contractors with Site Infrastructures to assist in expediting our projects for timely completions on behalf of our clients.
        </p>
        <!-- <p>
          C&C Industrial, Inc. is a turnkey Concrete and Civil contractor providing premier construction services since 1992. Our project types include heavy industrial, commercial, institutional, retail, & pharmaceutical. We are qualified and experienced in post tension, tilt walls, pervious concrete applications, and high-rise vertical concrete. We also specialize in site fine-grading, excavation & trenching, footings & foundations, parking lots & drives.
        </p>
        <p>C&C Industrial, Inc. also provides outstanding concrete pump services with an in-house fleet of boom trucks, a Tele-Belt as well as laser-screed and specialized concrete finish services. The company is also an authorized service provider for Concrete Washout Systems (CWS). CWS is an environmentally safe practice of containing and disposing of concrete washout waste from the fleet trucks that deliver the concrete to our project sites.
        </p>
        <p>
          Our company is fully bonded & insured, and we place jobsite safety as the number one priority for all our projects. Located along Interstate 95 in Rocky Mount NC, we are well-positioned to service the Carolinas and Virginia.
        </p>
        <p>
          C&C Industrial, Inc. willfully employees nearly 200 employees with an average tenure of over 7 years. With about 15% of whom have been with the company in excess of 10 years or more. We also utilize the resources of specialty trades (subcontractors) such as, Rod Busters to install reinforcements, Demo experts in renovations, and Utility contractors with Site Infrastructures to assist in expediting our projects for timely completions on behalf of our clients.
        </p> -->
      </v-col>
      <template v-if="$vuetify.breakpoint.lgAndUp">
        <v-col class="mx-8 shrink">
          <v-divider
            vertical
            class="grey darken-3"
          />
        </v-col>
        <v-col
          cols="3"
          xl="2"
        >
          <h4 class="text-h6 white--text text-uppercase">
            Project Types & Expertise
          </h4>
          <v-card
            color="transparent"
            flat
          >
            <v-divider class="primary mt-2 mb-n1" />
            <template v-for="(i, idx) in projectTypes">
              <div
                :key="idx"
                class="white--text text-center text-overline"
              >
                {{ i.value}}
              </div>
              <v-divider
                :key="`${idx}-divider`"
                class="my-n1 grey darken-3"
              >
                fa-horizontal-rule
              </v-divider>
            </template>

            <!-- <v-card-text class="grey darken-3"> -->
            <!-- <ul>
                <li
                  v-for="(i, idx) in projectTypes"
                  :key="idx"
                  class="grey--text text--lighten-2"
                >
                  {{ i.value }}
                </li>
              </ul> -->
            <!-- </v-card-text> -->
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
      </div>
</section>
</template>

<script>
export default {
  data () {
    return {
      projectTypes: [
        {
          value: 'Heavy Industrial'
        },
        {
          value: 'Commercial'
        },
        {
          value: 'Institutional'
        },
        {
          value: 'Retail'
        },
        {
          value: 'Pharmaceutical'
        },
        {
          value: 'Post Tension'
        },
        {
          value: 'Tilt Walls'
        },
        {
          value: 'Pervious Concrete Applications'
        },
        {
          value: 'High-rise Vertical Concrete'
        },
        {
          value: 'Site fine-grading'
        },
        {
          value: 'Excavation & Trenching'
        },
        {
          value: 'Footings & Foundations'
        },
        {
          value: 'Parking Lots & Drives'
        }
      ]
    }
  }
}
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.45);
  transition: background-color 100ms linear;
}
.overlay:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transition: background-color 180ms linear;
}
</style>
