<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        lg="6"
        xl="6"
        class="grey--text text--lighten-2"
      >
        <p>
          <span class="cc-paragraph-lead primary--text">Our Pumping &amp; Conveying Division</span> consists of various sizes of Boom Pumps, a Line Pump, and a Tele-Belt. Our pumps often go hand in hand with our concrete services however, they are for rent to outside customers who have the need for pumping or conveying needs. Our Boom Pumps are utilized for those hard to reach places whether it should be in a footing well below grade that we can not get the concrete supplier trucks to, or it may be for those elevated slabs. We utilize the Line Pumps for much smaller jobs to ease the over all cost to our clients. Our Tele-Belt is a unique piece of our Fleet. It can be utilized in various types of applications from placement of concrete, stone, or sand.
        </p>
        <div class="text-right">
          <v-btn
            v-if="$vuetify.breakpoint.mdAndDown"
            color="info"
            elevation="0"
            small
            to="/concrete-pumping-conveying"
          >
            Learn More
            <v-icon right>
              fa-chevron-right
            </v-icon>
          </v-btn>
        </div>
      </v-col>
      <AboutLearnMore
        v-if="$vuetify.breakpoint.lgAndUp"
        :cta="cta"
        label="Pumping & Conveying"
        uuid="b26b6cb9-ac14-4966-ae97-496b3fbb4a80"
        url="/concrete-pumping-conveying"
      />
    </v-row>

  </div>
</template>

<script>
export default {
  props: {
    cta: {
      type: String,
      default: 'right'
    }
  },
  components: {
    AboutLearnMore: () => import(/* webpackChunkName: "about-learn-more" */ './AboutLearnMore.vue')
  }
}
</script>
