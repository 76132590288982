<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        lg="6"
        xl="6"
        class="grey--text text--lighten-2"
      >
        <p>
          <span class="cc-paragraph-lead primary--text">Our Place &amp; Finish</span> division is able to meet the demands of the industry by owning the equipment it requires to be number one in the industry. Our four laser screeds, fleet of ride-ons, fleet of walk behinds, and seven soft cut saws are the reason we’re able to exceed our client expectations.
        </p>
        <div class="text-right">
          <v-btn
            v-if="$vuetify.breakpoint.mdAndDown"
            color="info"
            elevation="0"
            small
            to="/place-and-finish"
          >
            Learn More
            <v-icon right>
              fa-chevron-right
            </v-icon>
          </v-btn>
        </div>
      </v-col>
      <AboutLearnMore
        v-if="$vuetify.breakpoint.lgAndUp"
        :cta="cta"
        label="Place & Finish"
        uuid="a900f19e-f244-47c4-bf61-507bd74d4872"
        url="/place-and-finish"
      />
    </v-row>

  </div>
</template>

<script>
export default {
  props: {
    cta: {
      type: String,
      default: 'right'
    }
  },
  components: {
    AboutLearnMore: () => import(/* webpackChunkName: "about-learn-more" */ './AboutLearnMore.vue')
  }
}
</script>
