<template>
<div class="grey darken-4">
  <BannerHeader
    title="About Us"
    :height="$vuetify.breakpoint.mdAndDown ? '25vh' : '50vh'"
    uuid="e4a0b407-0c01-4efd-8030-2533d1256762"
  />
  <AboutIntro class="mt-3" />
  <div class="grey darken-4">
    <v-container>
      <AboutPreconstruction class="mb-16 py-4" />
      <AboutConcreteServices class="mb-16 py-4" cta="left" />
      <AboutCivilDivision class="mb-16 py-4" />
      <AboutPumpingConveying class="mb-16 py-4" cta="left" />
      <AboutPlaceAndFinish class="mb-16 py-4" />
      <AboutWashoutServices class="mb-16 py-4" cta="left" />
    </v-container>
  </div>
</div>
</template>

<script>
import BannerHeader from '@/core/components/BannerHeader'
import AboutIntro from './components/AboutIntro'
import AboutPreconstruction from './components/AboutPreconstruction'
import AboutConcreteServices from './components/AboutConcreteServices'
import AboutCivilDivision from './components/AboutCivilDivision'
import AboutPlaceAndFinish from './components/AboutPlaceAndFinish'
import AboutPumpingConveying from './components/AboutPumpingConveying'
import AboutWashoutServices from './components/AboutWashoutServices'

export default {
  metaInfo: {
    title: 'About',
    description: 'C&C Industrial, Inc. is a turnkey Concrete and Civil contractor providing premier construction services since 1992.'
  },
  components: {
    AboutIntro,
    AboutPreconstruction,
    AboutConcreteServices,
    AboutCivilDivision,
    AboutPlaceAndFinish,
    AboutPumpingConveying,
    AboutWashoutServices,
    BannerHeader
  }
}
</script>
