<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        lg="6"
        xl="6"
        class="grey--text text--lighten-2"
      >
        <p>
          <span class="cc-paragraph-lead primary--text">Washout Services</span>, CWS (Concrete Washout Services) is a division of C&C Industrial. Our washout services consist of containment containers for wastewater disposal, and solid waste recycling. C&C Industrial implemented this process to protect the environment.
        </p>
        <p>
          As safety is our number one priority, this not only includes our employees but, extends to our environment to protect our Natural Resources as well as the Wildlife. This system is designed for fleet trucks delivering the concrete to our projects, that allows them to wash down there trucks before leaving the job site all the while containing the elements and contaminants that could harm our environment and the wildlife impacted by construction sites.
        </p>
        <div class="text-right">
          <v-btn
            v-if="$vuetify.breakpoint.mdAndDown"
            color="info"
            elevation="0"
            small
            to="/washout-services"
          >
            Learn More
            <v-icon right>
              fa-chevron-right
            </v-icon>
          </v-btn>
        </div>
      </v-col>
      <AboutLearnMore
        v-if="$vuetify.breakpoint.lgAndUp"
        :cta="cta"
        label="Washout Services"
        uuid="88160c0d-e3bb-415e-960f-a87801dbc921"
        url="/washout-services"
      />
    </v-row>

  </div>
</template>

<script>
export default {
  props: {
    cta: {
      type: String,
      default: 'right'
    }
  },
  components: {
    AboutLearnMore: () => import(/* webpackChunkName: "about-learn-more" */ './AboutLearnMore.vue')
  }
}
</script>
