<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        lg="6"
        xl="6"
        class="grey--text text--lighten-2"
      >
        <p>
          <span class="cc-paragraph-lead primary--text">Our Concrete Services</span> range from a variety of diversified operations. We install foundations from the simplest of designs for single level structures, to the complex engineered design of high-rise tower facilities to include multi-level residentials to multi-level Parking Structures. Our slab pours vary from Slab on Grade to elevated Slabs on Deck and suspended slabs known as (Podium Slabs) again from a single level to a multi-level project. We also install CIP (Cast in Place) concrete walls and Pre-Cast Tilt Wall systems.
        </p>
        <div class="text-right">
          <v-btn
            v-if="$vuetify.breakpoint.mdAndDown"
            color="info"
            elevation="0"
            small
            to="/concrete-services"
          >
            Learn More
            <v-icon right>
              fa-chevron-right
            </v-icon>
          </v-btn>
        </div>
      </v-col>
      <AboutLearnMore
        v-if="$vuetify.breakpoint.lgAndUp"
        :cta="cta"
        label="Concrete Services"
        uuid="235d22c3-8194-4f27-bd97-ee32f09b2e7c"
        url="/concrete-services"
      />
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    cta: {
      type: String,
      default: 'right'
    }
  },
  components: {
    AboutLearnMore: () => import(/* webpackChunkName: "about-learn-more" */ './AboutLearnMore.vue')
  }
}
</script>
